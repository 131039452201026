.formats {
  width: 100%;

  &__container {
    max-width: 1310px;
    margin: 0 auto;
    padding: 100px 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__img {
    max-width: 170px;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-family: 'Gotham Pro';
    font-weight: 500;
    font-size: 12px;
    line-height: 110%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #1f1e1c;
  }

  &__title {
    margin: 12px 0 24px;
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    color: #1f1e1c;
  }

  &__text {
    font-size: 16px;
    line-height: 154.05%;
    p {
      font-family: 'Gotham Pro';
      color: #1f1e1c;
    }
    margin-bottom: 40px;
    p:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__col {
    margin-right: 64px;
    max-width: 524px;
    min-width: 524px;

    .button {
      width: fit-content;
    }
  }

  &__item {
    border-radius: 12px;

    padding: 32px;
    &-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 142.2%;
      color: #1f1e1c;
      margin-bottom: 12px;
    }
    &-text {
      font-family: 'Gotham Pro';
      font-size: 16px;
      line-height: 154.05%;
    }
    &:first-child {
      background: #f8dccd;
    }
    &:nth-child(2) {
      background: #f0c255;
    }

    &:last-child {
      background: #b7cb8d;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 1330px) {
    &__container {
      padding: 64px 16px;
    }
  }

  @media (max-width: 1024px) {
    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__col {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 48px;
    }
    &__title,
    &__subtitle,
    &__text {
      text-align: center;
    }

    &__list {
      max-width: 524px;
    }
  }

  @media (max-width: 576px) {
    &__col {
      min-width: auto;
    }
  }

  @media (max-width: 480px) {
    &__title {
      font-size: 24px;
      max-width: 300px;
      margin: 12px 0 16px;
    }

    .button {
      width: 100%;
      margin-top: 24px;
    }

    &__col {
      margin-bottom: 24px;
      align-items: flex-start;
    }

    &__title,
    &__subtitle,
    &__text {
      text-align: left;
    }

    &__subtitle {
      font-size: 10px;
    }

    &__text {
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 0;

      p:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__item {
      &-title {
        font-size: 16px;
      }
    }
  }
}

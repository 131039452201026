.header {
  width: 100%;
  &__top {
    padding: 14px 32px;
    background: #1f1e1c;
    font-size: 15px;
    line-height: 14px;
    display: flex;
    align-items: center;

    /* #FFFFFF */

    color: #ffffff;
    justify-content: center;
    text-align: center;
  }

  &__container {
    max-width: 1151px;
    margin: 0 auto;
    margin-top: 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    margin-right: 40px;
    cursor: pointer;
  }

  &__func {
    display: flex;
    align-items: center;
    margin-left: 40px;
  }

  .lang-picker {
    margin-right: 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    &-icon {
      width: 40px;
      height: 40px;
      min-width: 40px;
      background: #f1f1f1;
      border-radius: 6px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      path {
        fill: #000;
      }
    }
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    color: #1f1e1c;
    text-decoration: none;
    &:not(:last-child) {
      margin-right: 24px;
    }

    &:hover {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }

  @media (max-width: 1200px) {
    &__container {
      padding: 0 16px;
    }

    &__logo {
      margin-right: 24px;
    }

    &__func {
      margin-left: 24px;
    }

    &__logo {
      width: 56px;
      height: 56px;
    }

    &__item {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 1120px) {
    &__item {
      span {
        display: none;
      }
      &-icon {
        margin-right: 0;
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 900px) {
    &__logo {
      width: 63px;
      height: 62px;
    }
  }

  @media (max-width: 600px) {
    &__top {
      font-size: 13px;
    }
  }
  @media (max-width: 480px) {
    &__top {
      display: none;
    }
    &__container {
      margin-top: 0;
      padding: 16px;
    }
  }
}

.portfolio {
  width: 100%;
  margin-top: 80px;
  &__container {
    max-width: 1310px;
    margin: 0 auto;
  }

  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    color: #1f1e1c;
    margin: 32px 0 20px;
  }

  &__text {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #1f1e1c;
  }

  &__block {
    background: #f1f1f1;
    border-radius: 12px;
    padding: 64px 0 68px 64px;
    display: flex;
    width: fit-content;
    align-items: flex-start;
  }
  .button {
    display: none;
  }
  &__col {
    max-width: 459px;
    margin-right: 71px;
    .button {
      display: flex;
    }
  }

  &__row {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 86px;
    &-title {
      font-weight: 800;
      font-size: 40px;
      line-height: 110%;
      color: #1f1e1c;
      margin-bottom: 16px;
      span {
        font-weight: 700;
        font-size: 14px;
        line-height: 110%;
        display: block;
        color: #1f1e1c;
        margin-bottom: 0;
      }
    }
    &-text {
      font-family: 'Gotham Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      color: #1f1e1c;
    }
  }

  .button {
    width: fit-content;
    margin-top: 48px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    row-gap: 8px;
    margin-right: -194px;
    max-width: 552px;

    @media (min-width: 1441px) {
      max-width: 650px;
    }
  }

  &__hands {
    display: flex;
  }

  &__img {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    isolation: isolate;
    cursor: pointer;
    padding: 12px;
    border: 3px solid transparent;
    transition: 0.3s ease;

    img {
      border-radius: 12px;

      width: 100%;
      object-fit: cover;
    }

    &:hover {
      border: 3px solid #000000;
    }
  }

  @media (max-width: 1330px) {
    &__container {
      padding: 0 16px;
    }

    &__block {
      padding-right: 32px;
      align-items: center;
    }

    &__list {
      margin-right: 0;
    }

    &__col {
      margin-right: 48px;
    }
  }

  @media (max-width: 1024px) {
    &__list {
      margin-right: -200px;
      margin-top: 48px;
      margin-left: 200px;
      grid-template-columns: repeat(3, 1fr);
      max-width: 700px;
    }

    &__block {
      flex-direction: column;
      align-items: flex-start;
    }
    &__col {
      margin-right: 0;
    }
  }

  @media (max-width: 860px) {
    &__list {
      margin-right: 0;
      margin-top: 48px;
      margin-left: 0;
    }

    .button {
      display: flex;
    }
    &__col {
      .button {
        display: none;
      }
    }
    &__block {
      padding: 64px 32px 64px;
    }
  }

  @media (max-width: 710px) {
    &__list {
      margin-right: 0;
      margin-top: 48px;
      margin-left: 0;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 576px) {
    &__list {
      width: 100%;
      margin-top: 32px;
      grid-template-columns: repeat(1, 1fr);
    }

    .button {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    margin-top: 80px;
    &__block {
      padding: 0;
      background: transparent;
    }
    .button {
      width: 100%;
      margin-top: 55px;
    }
    &__title {
      font-size: 24px;
      max-width: 300px;
      margin: 24px 0 16px;
    }
    &__col {
      svg {
        width: 80px;
        height: 100px;
      }
    }

    &__img {
      padding: 6px;
    }

    &__row {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0;
      row-gap: 30px;
      &-col {
        max-width: 250px;
      }
      &-title {
        font-size: 40px;
        margin-bottom: 12px;
      }
    }
  }
}

.navigation {
  display: flex;
  align-items: center;
  flex-grow: 1;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #1f1e1c;

    transition: 0.25s ease;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &:hover {
      color: #ff8324;
    }
  }

  @media (max-width: 1200px) {
    &__item {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 900px) {
    display: none;
  }
}

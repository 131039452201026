.cost {
  width: 100%;
  margin-top: 160px;
  &__container {
    max-width: 1310px;
    margin: 0 auto;
  }

  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    text-align: left;
    color: #272c24;
    margin-bottom: 24px;
  }

  &__text {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: left;
    color: #1f1e1c;
  }

  &__img {
    position: absolute;
    right: 0;
    top: -140px;
    display: flex;
  }
  &__list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    margin-top: 64px;
  }
  &__item {
    padding: 32px;
    border-radius: 12px;
    background: #f1f1f1;
    display: flex;
    flex-direction: column;
    &-subtitle {
      width: fit-content;
      padding: 8px 10px;
      background: #ffffff;
      border-radius: 32px;
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      /* #FEF7B22 */

      color: #ff8324;
    }
    &-unic {
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: #1f1e1c;
      margin-top: 12px;
      text-align: center;
    }
    &-title {
      font-weight: 800;
      font-size: 28px;
      line-height: 120%;
      color: #272c24;
      margin-bottom: 12px;
    }
    &-old {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #454544;

      span {
        text-decoration-line: line-through;
      }
    }
    &-new {
      margin-top: 32px;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      display: flex;
      align-items: flex-end;

      /* #454544 */

      color: rgba($color: #454544, $alpha: 0.7);

      span {
        font-weight: 800;
        font-size: 32px;
        line-height: 80%;
        color: #272c24;
        margin-right: 6px;
      }
    }
    &-subtext {
      margin-top: 10px;
      font-family: 'Gotham Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 150%;
      color: #1f1e1c;
    }

    .button {
      margin-top: 20px;
    }
    &-list {
      margin-top: 34px;
      padding-top: 34px;
    }
    &-param {
      display: flex;
      align-items: center;
      font-family: 'Gotham Pro';
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: #1f1e1c;

      &.no-chosen {
        font-weight: 400;
        color: #454544;
      }

      svg {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        min-width: 24px;
      }

      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }
  }

  &__item-top {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 32px;
    background: #f1f1f1;
    margin: -32px;
    flex-grow: 1;
    border-radius: 12px 12px 0px 0px;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #454544;
      opacity: 0.2;
      border-radius: 16px;
    }
  }

  @media (max-width: 1330px) {
    &__container {
      padding: 0 16px;
    }

    &__list {
      column-gap: 20px;
    }

    &__img {
      width: 200px;
      top: -105px;
      img {
        width: 100%;
      }
    }

    &__item {
      padding: 16px;
      &-top {
        padding: 16px;
        background: #f1f1f1;
        margin: -16px;
        padding-bottom: 24px;
      }
      &-subtitle {
        font-size: 10px;
      }
      &-title {
        font-size: 24px;
      }
      &-list {
        margin-top: 32px;
      }
    }
  }

  @media (max-width: 990px) {
    &__container {
      position: relative;
    }
    &__img {
      display: none;
      top: 0;
    }

    &__list {
      position: static;
      display: flex;
      grid-template-columns: none;
      overflow: auto;
      margin: 48px -16px 0;
      padding: 0 16px;
      column-gap: 30px;
      padding-bottom: 20px;
      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-track {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }

    &__item {
      padding: 32px;
      min-width: 362px;
      column-gap: 30px;
      &-top {
        padding: 0;
        padding-bottom: 24px;
        background: #f1f1f1;
        margin: 0;
        position: relative;
        z-index: 0;
      }
    }
  }

  @media (max-width: 480px) {
    margin-top: 80px;
    &__title {
      font-size: 24px;
      text-align: left;
      margin-bottom: 16px;
    }

    &__text {
      max-width: 300px;
      margin: 0 0;
      text-align: left;
    }

    &__list {
      margin: 24px -16px 0;
      column-gap: 16px;
    }

    &__item {
      padding: 16px;
      max-width: 266px;
      min-width: 266px;
      &-old {
        font-size: 14px;
      }
      &-title {
        font-size: 22px;
      }
      &-subtitle {
        max-width: 190px;
        margin-bottom: 12px;
      }
      &-top {
        padding-bottom: 40px;
      }
      &-new {
        margin-top: 24px;

        span {
          font-size: 28px;
        }
      }
      &-subtext {
        font-size: 10px;
      }
      &-list {
        padding-top: 32px;
        margin-top: 0;
      }
      &-param {
        svg {
          width: 20px;
          height: 20px;
          min-width: 20px;
        }
      }
    }
  }

  &.new-block-cost {
    margin-top: 124px;
    .cost__container {
      max-width: 1146px;
      margin: 0 auto;
      background: #f1f1f1;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 40px 93px 40px 64px;
      justify-content: space-between;
    }
    .cost__item {
      .button {
        display: none;
      }
      &-top {
        padding: 0 0 24px;
        margin: 0;
        background: transparent;
      }
      &-list {
        max-height: 154px;
      }
    }
    .cost__col {
      max-width: 510px;
      width: 100%;
      margin-right: 117px;
    }
    .cost__img {
      position: absolute;
      right: 0;
      top: -140px;
      width: 225px;
      img {
        width: 100%;
      }
    }

    .cost__block {
      position: relative;
    }
    .button {
      margin-top: 36px;
      max-width: 412px;
    }
    .cost__item {
      padding: 32px;
      border-radius: 12px;
      background: #ffff;
      display: flex;
      flex-direction: column;
      max-width: 362px;

      &-top {
        position: relative;
        padding-bottom: 24px;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: #454544;
          opacity: 0.2;
          border-radius: 16px;
        }
      }
      &-subtitle {
        width: fit-content;
        padding: 8px 10px;
        background: #ffffff;
        border-radius: 32px;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        /* #FEF7B22 */

        color: #ff8324;
      }
      &-unic {
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: #1f1e1c;
        margin-top: 12px;
        text-align: center;
      }
      &-title {
        font-weight: 800;
        font-size: 28px;
        line-height: 120%;
        color: #272c24;
        margin-bottom: 12px;
      }
      &-list {
        max-height: 147px;
        overflow: auto;
        margin-top: 24px;
        padding-top: 8px;
        &::-webkit-scrollbar {
          width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px #bdbdbd;
          border-radius: 12px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #272c24;
          border-radius: 12px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #000;
        }
      }
      &-old {
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #454544;

        span {
          text-decoration-line: line-through;
        }
      }
      &-new {
        margin-top: 32px;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        /* or 18px */

        display: flex;
        align-items: flex-end;

        /* #454544 */

        color: rgba($color: #454544, $alpha: 0.7);

        span {
          font-weight: 800;
          font-size: 32px;
          line-height: 80%;
          color: #272c24;
          margin-right: 6px;
        }
      }
      &-subtext {
        margin-top: 10px;
        font-family: 'Gotham Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 150%;
        color: #1f1e1c;
      }

      &-param {
        display: flex;
        align-items: center;
        font-family: 'Gotham Pro';
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        color: #1f1e1c;
        padding-right: 16px;
        &.no-chosen {
          font-weight: 400;
          color: #454544;
        }

        svg {
          margin-right: 12px;
          width: 24px;
          height: 24px;
          min-width: 24px;
        }

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }

    @media (max-width: 1330px) {
      .cost__container {
        padding: 40px 48px 40px 32px;
      }

      .cost___col {
        margin-right: 48px;
      }
    }

    @media (max-width: 990px) {
      .cost__container {
        flex-direction: column;
        padding: 32px 16px;
      }

      .cost__col {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        margin-bottom: 32px;
      }

      .cost__item {
        max-width: 412px;
        min-width: auto;
      }

      .button {
        width: 100%;
      }

      .cost__title,
      .cost__text {
        text-align: center;
      }
    }

    @media (max-width: 480px) {
      .cost__col {
        align-items: flex-start;
      }

      .button {
        margin-top: 32px;
      }
      .cost__item {
        padding: 16px;
      }
    }
  }
}

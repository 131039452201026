.program {
  width: 100%;
  &__container {
    max-width: 1310px;
    margin: 0 auto;
    padding: 80px 0 0;
    display: flex;
    justify-content: space-between;
    min-height: 1700px;
  }

  &__img {
    width: 100%;
    max-width: 460px;
    width: 460px;
    min-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
    img {
      display: flex;

      &.lax {
        opacity: 0;
      }
      &.box {
        opacity: 1;
        position: relative;
        z-index: 1;
        backface-visibility: hidden;
        margin-bottom: 9px;
      }
    }
    & > div {
      width: auto !important;
      height: auto !important;
      overflow: initial !important;
      display: flex;
    }
  }

  &__icons {
    width: 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__list {
    height: 100%;
    transition: 0.3s;
  }

  &__col {
    padding-top: 65px;
    max-width: 740px;
  }

  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    color: #272c24;
    max-width: 480px;
    margin-bottom: 40px;
  }

  &__item {
    padding: 0 40px 0;
    border-radius: 8px;
    transition: 0.35s ease;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        transition: 0.3s ease;
      }
      path {
        transition: 0.3s ease;
      }
    }
    &-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 142.2%;

      color: #1f1e1c;
      transition: 0.3s ease;
    }
    &-text {
      font-family: 'Gotham Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #535353;
      padding-right: 36px;
      transition: 0.3s ease;
      display: none;
    }

    &:hover {
      .program__item-title {
        color: #ff8324;
        text-decoration-line: underline;
        text-underline-offset: 4px;
      }
      path {
        fill: #ff8324;
      }
    }
    &.active {
      background: #f1f1f1;
      transition: 0.3s;
      .program__item-title {
        text-decoration-line: underline;
        text-underline-offset: 4px;
      }
      svg {
        transform: rotate(-180deg);
      }
      padding: 16px 40px 40px;
      .program__item-text {
        display: block;
      }
    }
  }

  @media (max-width: 1330px) {
    &__container {
      padding: 80px 16px 0;
    }
  }

  @media (max-width: 1024px) {
    &__container {
      flex-direction: column-reverse;
      max-height: none;
    }

    &__col {
      width: 100%;
      max-width: none;
    }

    &__img {
      margin-right: 0;
      margin-top: 32px;
      align-self: center;
      & > div {
        &.programs__icons {
          display: none !important;
        }
      }
      img.lax {
        display: none;
      }

      img.box {
        display: none;
      }
    }
  }

  @media (max-width: 900px) {
    &__container {
      padding: 0 16px 0;
    }

    &__col {
      padding-top: 0;
    }
    &__item {
      padding: 0 32px 0;
      &.active {
        padding: 16px 32px 16px;
      }
    }
  }

  @media (max-width: 480px) {
    padding-top: 56px;
    &__title {
      font-size: 24px;
      max-width: 280px;
      margin-bottom: 32px;
    }

    &__img {
      min-width: auto;
      width: 100%;
      & > div {
        width: 100% !important;
        height: 100% !important;
      }
    }

    &__item {
      // padding: 0 16px 0;
      padding: 16px 30px 0px;
      &-title {
        font-size: 14px;
      }
      &-text {
        font-size: 14px;
        padding-right: 24px;
      }
      &-top {
        // padding: 16px 0;
        svg {
          width: 20px;
          height: 20px;
          min-width: 20px;
          margin-left: 12px;
        }
      }
      &.active {
        padding: 16px 30px 40px;
      }
    }
  }
}

.results {
  width: 100%;
  margin-top: 80px;

  &__container {
    max-width: 1310px;
    margin: 0 auto;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 70px;
    row-gap: 30px;
  }

  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    color: #272c24;
    margin-bottom: 64px;
  }

  &__item {
    display: flex;
    align-items: center;
    &-img {
      width: 240px;
      min-width: 240px;
      display: flex;
      margin-right: 8px;
      align-items: center;
      justify-content: center;
      img {
      }
    }
    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #272c24;
      margin-bottom: 12px;
    }
    &-text {
      font-family: 'Gotham Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      /* or 22px */

      /* #272C24 */

      color: #272c24;
    }
  }

  @media (max-width: 1330px) {
    padding: 0 16px;

    &__list {
      column-gap: 30px;
    }

    &__item {
      &-img {
        width: 180px;
        min-width: 180px;

        img {
          width: 100%;
          object-fit: scale-down;
        }
      }
    }
  }

  @media (max-width: 900px) {
    &__list {
      column-gap: 0;
      grid-template-columns: repeat(1, 1fr);
    }

    &__title {
      margin-bottom: 48px;
    }

    &__item {
      justify-content: center;
    }

    &__item-img {
      margin-right: 32px;
    }

    &__item-col {
      max-width: 400px;
    }
  }

  @media (max-width: 480px) {
    &__title {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &__item {
      flex-direction: column;
      align-items: center;
      &-img {
        width: auto;
        min-width: auto;
        margin-bottom: 32px;
        margin-right: 0;
        max-width: 180px;
      }
      &-col {
        max-width: 300px;
      }
      &-title,
      &-text {
        text-align: center;
      }

      &-title {
        font-size: 18px;
      }
    }
  }
}

.intro {
  width: 100%;

  --vh: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  margin-top: 40px;

  &__container {
    max-width: 1151px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0 64px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }

  &__col {
    max-width: 588px;
  }

  &__subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    color: #1f1e1c;
  }
  &__title {
    font-weight: 800;
    font-size: 64px;
    line-height: 64px;
    color: #1f1e1c;
    margin: 12px 0 24px;
  }
  &__text {
    font-family: 'Gotham Pro';

    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #1f1e1c;
    margin-bottom: 24px;

    @media (max-width: 480px) {
      margin-bottom: 20px;
    }
  }

  &__form {
    margin-top: 40px;
    display: flex;
    align-items: center;
    position: relative;
    &.error {
      &::after {
        content: var(--input-error);
        position: absolute;
        top: -26px;
        height: 26px;
        left: 0;
        background-color: #ff4500;
        font-size: 14px;
        color: white;
        line-height: 27px;
        padding: 0 10px;
        border-radius: 6px;
        z-index: 1;
      }

      .input {
        border-color: #ff4500;
      }
    }
  }

  &__img {
    max-width: 50%;
    // width: calc(50% - 95px);
    margin-left: 95px;
    display: flex;
    align-items: center;
    max-height: calc(var(--vh, 1vh) * 100 - 140px);
    animation: bicycle 1.25s;

    @keyframes bicycle {
      0% {
        transform: translateX(150px);
        opacity: 0.3;
      }
      100% {
        transform: translateX(0px);
        opacity: 1;
      }
    }
    & > div {
      display: flex;

      @media (max-width: 1380px) {
        // width: auto !important;
      }

      @media (max-width: 1280px) and (min-height: 1024px) {
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 100% !important;
      }
    }

    @media (min-width: 1537px) {
      width: calc(50% - 95px);
    }

    @media (min-width: 1601px) {
      flex-grow: 1;
    }

    @media (max-width: 1440px) and (min-height: 688px) and (max-height: 899px) {
      max-width: 38%;
      width: calc(38% - 95px);
    }

    svg {
      height: auto !important;
    }
  }

  &__left {
    max-width: 780px;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }
  &__bottom {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    display: flex;
    align-items: center;

    /* #1F1E1C */

    color: #1f1e1c;
    &-icon {
      background: #f1f1f1;
      border-radius: 7px;
      width: 50px;
      height: 50px;
      min-width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  &__social {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1f1e1c;
    transition: 0.25s ease;
    cursor: pointer;

    &:hover {
      background: #ff8324;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 1200px) {
    &__container {
      padding: 0 16px 96px;
    }
    &__img {
      width: calc(50% - 45px);
      margin-left: 45px;
    }
  }

  @media (max-width: 1000px) {
    &__img {
      display: none;
    }

    &__bottom {
      flex-direction: column;
    }

    &__left {
      margin-bottom: 32px;
    }

    &__row {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__subtitle,
    &__title,
    &__text {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    &__container {
      padding: 0 16px 72px;
    }
  }

  @media (max-width: 700px) {
    &__left {
      display: none;
    }
  }

  @media (max-width: 480px) {
    margin-top: 24px;
    &__subtitle {
      font-size: 10px;
    }

    &__col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      font-size: 35px;
      line-height: 40px;
      color: #1f1e1c;
      margin: 8px 0 20px;
      max-width: 280px;
    }

    &__text {
      font-size: 16px;
    }

    &__form {
      width: 100%;
      flex-direction: column;
      margin-top: 32px;

      .input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 350px) {
    &__title {
      font-size: 32px;
      line-height: 140%;
      color: #1f1e1c;
      margin: 8px 0 16px;
      max-width: 225px;
    }
  }
}

.reviews {
  width: 100%;
  margin-top: 160px;
  overflow: hidden;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 64px;
  }

  &__container {
    margin: 0 auto;
    max-width: 950px;
  }

  &__title {
    font-weight: 800;
    font-size: 50px;
    line-height: 110%;
    color: #272c24;
    margin-right: 32px;
  }

  &__btns {
    display: flex;
    align-items: center;
  }

  &__btn {
    background: #ffffff;
    border: 2px solid #1f1e1c;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    cursor: pointer;
    transition: 0.3s ease;

    svg {
      width: 24px;
      height: 24px;
    }
    path {
      transition: 0.3s ease;
    }
    &:first-child {
      margin-right: 8px;
    }
    &.disabled-bt {
      pointer-events: none;
      border-color: rgba($color: #000000, $alpha: 0.5);

      path {
        fill: rgba($color: #000000, $alpha: 0.5);
      }
    }

    &.back {
      svg {
        transform: rotate(90deg);
      }
    }
    &.next {
      svg {
        transform: rotate(-90deg);
      }
    }

    &:hover {
      background: #1f1e1c;
      path {
        fill: #fff;
      }
    }
  }

  .slick-arrow {
    z-index: 1;
    background: #ffffff;
    border: 2px solid #1f1e1c;
    border-radius: 8px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 13px;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      background: #1f1e1c;
      path {
        fill: #fff;
      }
    }
    &.slick-disabled {
      pointer-events: none;
      border-color: rgba($color: #000000, $alpha: 0.5);

      path {
        fill: rgba($color: #000000, $alpha: 0.5);
      }
    }
  }
  .slick-prev {
    transform: rotate(90deg);
  }
  .slick-next {
    transform: rotate(-90deg);
  }

  &__item {
    &-row {
      display: flex;
    }
    &-block:not(:last-child) {
      margin-bottom: 56px;
    }
    &-img {
      width: 166px;
      min-width: 166px;
      height: 166px;
      overflow: hidden;
      isolation: isolate;
      border-radius: 6px;
      display: flex;
      img {
        width: 100%;
        object-fit: cover;
      }
      margin-right: 30px;
    }
    &-title {
      font-weight: 700;
      font-size: 22px;
      line-height: 130%;

      color: #272c24;
      margin-bottom: 12px;
    }

    &-text {
      span:not(.reviews__item-more) {
        font-family: 'Gotham Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 154.05%;
        color: #272c24;
      }

      span:not(:first-child) {
        display: none;
      }
      span.reviews__item-more {
        display: inline;
      }
    }
    &-more {
      cursor: pointer;
      font-family: 'Gotham Pro';
      font-size: 16px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
      text-decoration: underline;
      text-underline-offset: 4px;
      white-space: nowrap;
      transition: 0.25s ease;

      &:hover {
        color: #ff8324;
      }
    }

    &-socials {
      display: flex;
      margin-top: 16px;
      a {
        width: 40px;
        background: #1f1e1c;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: 0.25s ease;
        position: relative;
        z-index: 1;

        svg {
          position: relative;
          z-index: 1;
        }

        &:hover {
          background: #ff8324;
        }

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .slick-track {
    display: flex;
  }
  .slick-slide {
    opacity: 0;
    transition: 0.3s ease;

    &.slick-current {
      opacity: 1;
    }
  }

  &__img {
    display: flex;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 8px;

    .button {
      margin-top: 80px;
    }
  }

  @media (max-width: 1024px) {
    &__container {
      padding: 0 16px;
    }
  }

  @media (max-width: 768px) {
    &__item-block:not(:last-child) {
      margin-bottom: 0;
    }

    &__top {
      flex-direction: column;
    }

    &__title {
      text-align: center;
      margin-right: 0;
    }

    &__list {
      position: relative;
    }

    &__bottom {
      flex-direction: column;
      align-items: center;
      .button {
        margin-top: 48px;
        margin-bottom: 32px;
      }
    }

    .slick-arrow {
      position: absolute;
      top: 50px;
      left: 24px;
    }
    .slick-next {
      left: auto;
      right: 24px;
    }

    &__item {
      &-img {
        margin-right: 0;
        margin-bottom: 35px;
      }
      &-row {
        flex-direction: column;
        align-items: center;
        padding: 0 32px;
      }
      &-title {
        margin-bottom: 14px;
        text-align: center;
      }
      &-socials {
        justify-content: center;
        margin-top: 32px;
      }
      &-text {
        text-align: center;
        span {
          text-align: center;
        }
        span.reviews__item-more {
          display: block;
          width: fit-content;
          margin: 16px auto 0;
        }
      }
    }
  }

  @media (max-width: 480px) {
    margin-top: 140px;
    &__title {
      font-size: 32px;
    }

    &__top {
      margin-bottom: 32px;
    }

    &__item {
      &-img {
        width: 124px;
        min-width: 124px;
        height: 124px;
      }
      &-title {
        font-size: 16px;
      }
      &-text {
        span {
          font-size: 14px;
        }
      }
    }
  }
}

.prefer {
  width: 100%;
  &__container {
    max-width: 1310px;
    margin: 0 auto;
    padding: 160px 0 80px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 32px;
  }
  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    color: #1f1e1c;
    max-width: 464px;
  }

  &__text {
    font-family: 'Gotham Pro';
    font-size: 16px;
    line-height: 154.05%;
    color: #1f1e1c;
    max-width: 420px;
  }

  &__list {
    padding-top: 57px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
    position: relative;
  }

  &__item {
    border-radius: 8px;
    padding: 52px 40px 48px;
    &-icon {
      margin-top: -96px;
      margin-bottom: 24px;
    }
    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;

      color: #181818;
      margin-bottom: 16px;
    }
    &-text {
      font-family: 'Gotham Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      /* or 22px */

      /* #1F1E1C */

      color: #1f1e1c;
    }
    &:first-child {
      background: #ff8659;
    }

    &:nth-child(2) {
      background: #f2bc40;
    }
    &:nth-child(3) {
      background: #b7cb8d;
    }
    &:last-child {
      background: #f8dccd;
    }
  }

  @media (max-width: 1330px) {
    &__container {
      padding: 80px 16px 80px;
    }
    &__list {
      column-gap: 20px;
    }

    &__item {
      padding: 52px 32px 40px;
    }
  }

  @media (max-width: 1024px) {
    &__list {
      column-gap: 30px;
      row-gap: 64px;
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      position: relative;
    }
  }

  @media (max-width: 768px) {
    &__container {
      padding: 64px 16px;
    }

    &__top {
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      margin-bottom: 24px;
    }
    &__list {
      column-gap: 20px;
    }
    &__item {
      &-icon {
        position: relative;
        top: 0;
        transform: none;
        margin-bottom: 24px;
        margin-top: -104px;
      }
    }
  }

  @media (max-width: 600px) {
    &__list {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0;
      row-gap: 74px;
    }
  }

  @media (max-width: 480px) {
    &__container {
      padding: 0 16px 64px;
    }
    &__item {
      padding: 40px 32px 32px;
      &-icon {
        margin-top: -96px;
      }
      &-title {
        font-size: 18px;
        margin-bottom: 12px;
      }
    }

    &__title {
      font-size: 24px;
      max-width: 280px;
    }

    &__text {
      font-size: 14px;
    }
  }
}

.awwards {
  width: 100%;
  margin-top: 190px;
  padding-bottom: 190px;
  &__container {
    max-width: 1310px;
    margin: 0 auto;
    background: #f1f1f1;
    border-radius: 12px;
    padding-top: 88px;
  }
  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    color: #272c24;
    max-width: 740px;
    margin: 0 auto 40px;
  }

  &__text {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: #1f1e1c;
    max-width: 440px;
    margin: 0 auto;
    a {
      color: #1f1e1c;
      transition: 0.25s ease;
      text-underline-offset: 3px;

      &:hover {
        color: #ff8324;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 35px;
    padding: 0 40px;
  }

  &__item {
    position: relative;
    top: 128px;
    margin-top: -64px;
    display: flex;

    img {
      width: 100%;
    }
  }

  @media (max-width: 1330px) {
    padding: 0 16px 190px;
  }

  @media (max-width: 1024px) {
    &__container {
      padding-top: 68px;
    }
    &__title {
      margin: 0 auto 32px;
    }

    &__item {
      position: relative;
      top: 64px;
      margin-top: -32px;
    }
  }

  @media (max-width: 900px) {
    &__list {
      column-gap: 20px;
      padding: 0 20px;
    }

    &__title {
      max-width: 628px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 16px 0;

    &__container {
      padding: 48px 32px 32px;
    }
    &__list {
      grid-template-columns: repeat(3, 1fr);
      row-gap: 20px;
      column-gap: 20px;
      margin-top: 48px;
    }
    &__item {
      position: relative;
      top: 0;
      margin-top: 0;
    }
  }

  @media (max-width: 480px) {
    margin-top: 120px;
    margin-bottom: 240px;
    padding: 0;
    &__container {
      background: #f1f1f1;
    }

    &__item {
      width: 183px;
      min-width: 183px;
    }

    &__title {
      font-size: 24px;
      margin: 0 0 24px;
      max-width: 315px;
      text-align: left;
    }
    &__text {
      text-align: left;
    }

    &__container {
      padding: 32px 16px 32px;
    }

    &__list {
      padding: 0;
      margin-top: 24px;
      row-gap: 10px;
      column-gap: 20px;
      display: flex;
      overflow: auto;
      margin: 24px -16px -180px;
      padding: 0 16px 32px;

      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-track {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }
  }
}

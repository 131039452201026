.thanks {
  max-width: 1151px;
  margin: 32px auto 0;
  padding: 0 0 56px;
  &__container {
    background: #f1f1f1;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__bottom {
    margin-top: 24px;
    display: flex;
    align-items: center;
    &-link {
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;

      transition: 0.25s ease;
      display: flex;
      align-items: center;
      text-decoration-line: underline;
      text-underline-offset: 3px;

      color: #000000;

      &:hover {
        color: #ff8324;
      }

      &:not(:last-child) {
        margin-right: 51px;
      }
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #1f1e1c;
  }

  &__title {
    font-weight: 800;
    font-size: 28px;
    line-height: 120%;
    color: #1f1e1c;
    margin: 10px 0 24px;
  }

  &__text {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */

    /* #454544 */

    color: #454544;
  }

  &__col {
    max-width: 503px;
    margin-right: 66px;
    padding: 64px 0 64px 94px;
  }

  &__subtext {
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    /* #1F1E1C */

    color: #1f1e1c;
    margin-top: 29px;
  }

  &__btn {
    display: flex;
    margin-top: 46px;
  }

  &__img {
    width: 373px;
    max-width: 373px;
    margin: 24px 57px 43px 0;
    img {
      width: 100%;
    }
  }

  .button {
    width: 187px;
    height: 60px;
    font-size: 16px;
    &:first-child {
      margin-right: 10px;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 16px 40px;

    &__col {
      padding: 32px 0 32px 32px;
    }
  }

  @media (max-width: 1024px) {
    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__img {
      align-self: center;

      margin: 0;
      height: 373px;
      margin-top: 32px;

      img {
        height: 100%;
      }
    }
    &__col {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__bottom {
      justify-content: center;
    }
    &__title,
    &__subtitle,
    &__text,
    &__subtext {
      text-align: center;
    }
    &__col {
      padding: 0;
    }

    &__container {
      padding: 48px;
    }
  }

  @media (max-width: 576px) {
    padding: 0 16px 40px;
    &__bottom {
      flex-direction: column;
      &-link {
        text-align: center;
      }
      &-link:not(:last-child) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 0 0 40px;
    margin: 0 auto 0;

    &__container {
      padding: 32px 24px 32px;
      border-radius: 0;
    }

    &__col {
      width: 100%;
    }

    &__img {
      width: 100%;
    }

    &__subtitle {
      font-size: 12px;
    }
    &__title {
      font-size: 18px;
    }

    &__subtext {
      font-size: 18px;
      margin-top: 24px;
    }

    &__btn {
      width: 100%;

      .button {
        width: calc(50% - 8px);
        height: auto;
        font-size: 14px;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}

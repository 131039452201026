.pop-up {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.25s ease;

  &.show {
    opacity: 1;
    visibility: visible;
    touch-action: auto;
    pointer-events: all;
    .pop-up__container {
      opacity: 1;
    }
  }

  &__container {
    max-width: 784px;
    max-height: 800px;
    overflow: hidden;
    background: #f1f1f1;
    border-radius: 6px;
    padding: 60px 41px 60px 80px;
    position: relative;

    opacity: 0;
    transition: 0.25s ease 0.15s;
  }

  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  &__title {
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    /* or 136% */

    display: flex;
    align-items: center;

    /* #272C24 */

    color: #272c24;
    margin-bottom: 24px;
    padding-right: 36px;
  }

  &__content {
    padding-right: 34px;
    overflow: auto;
    height: 500px;
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #bdbdbd;
      border-radius: 12px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #272c24;
      border-radius: 12px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #000;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 154.05%;
    /* or 25px */

    /* #272C24 */

    color: #272c24;

    span {
      font-family: 'Gotham Pro';
      font-style: normal;
      font-weight: 400;
      display: block;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    &-img {
      width: 60px;
      height: 60px;
      min-width: 60px;
      border-radius: 4px;
      margin-right: 12px;
      overflow: hidden;
      display: flex;
      img {
        width: 100%;
        height: 100%;
        margin-right: 12px;

        object-fit: cover;
      }
    }
    &-col {
      display: flex;
      margin-right: 38px;
    }
    &-socials {
      display: flex;
      align-items: center;
      a {
        width: 40px;
        height: 40px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #1f1e1c;
        transition: 0.25s ease;

        &:hover {
          background: #ff8324;
        }
        &:not(:last-child) {
          margin-right: 11px;
        }
      }
    }
    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      /* identical to box height, or 26px */

      /* #272C24 */

      color: #272c24;
      margin-bottom: 4px;
    }
    &-text {
      font-family: 'Gotham Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 154.05%;
      /* identical to box height, or 25px */

      /* #272C24 */

      color: #272c24;
    }
  }
  &__works {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    margin-top: 48px;
  }
  &__work {
    border-radius: 14px;
    padding: 12px;
    display: flex;

    img {
      width: 100%;
    }
    border: 3px solid transparent;
    &:hover {
      border: 3px solid #000000;
    }
  }
  @media (min-width: 1280px) and (min-height: 688px) {
    &__content {
      height: 430px;
    }
  }

  @media (min-height: 1024px) {
    &__content {
      height: 583px;
    }
  }

  @media (max-width: 900px) {
    &__container {
      max-width: 600px;
      padding: 30px 41px 30px 40px;
    }
  }

  @media (max-width: 660px) {
    &__container {
      max-width: none;
      width: 100%;
      margin: 24px;
      padding: 30px 41px 30px 40px;
    }

    &__content {
      padding-right: 16px;
    }

    &__title {
      font-size: 20px;
      line-height: 140%;
    }

    &__text {
      font-size: 14px;
    }
    &__works {
      margin-top: 24px;
    }

    &__work {
      padding: 6px;
    }
    &__user {
      flex-direction: column;
      align-items: flex-start;
      &-col {
        margin-right: 0;
      }
      &-socials {
        margin-top: 24px;
      }
      &-title {
        font-size: 16px;
      }
      &-text {
        font-size: 14px;
      }
      &-img {
        width: 50px;
        height: 50px;
        min-width: 50px;
      }
    }
  }

  @media (max-width: 576px) {
    &__container {
      padding: 24px;
      margin: 16px;
    }

    &__close {
      width: 32px;
      height: 32px;
    }
  }

  @media (max-width: 480px) {
    &__works {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.course-struct {
  padding: 87px 0 0;
  &__container {
    max-width: 1310px;
    margin: 0 auto;
  }
  &__subtitle {
    font-family: 'Gotham Pro';
    font-weight: 500;
    font-size: 12px;
    line-height: 110%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #1f1e1c;
    text-align: center;
  }

  &__title {
    margin: 12px 0 0;
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    color: #1f1e1c;
    text-align: center;
  }

  &__top {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    position: relative;
  }

  &__img {
    position: absolute;
    top: -140px;
    left: 24px;
    width: 100%;
    max-width: 218px;
    z-index: 1;

    svg {
      width: 100%;
    }
  }

  &__item {
    padding: 32px;
    border-radius: 12px;
    &-title {
      font-family: 'Montserrat Alternates';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 142.2%;
      /* identical to box height, or 26px */

      /* #1F1E1C */

      color: #1f1e1c;
      margin-bottom: 12px;
    }
    &-text {
      font-family: 'Gotham Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 154.05%;
      /* or 25px */

      /* #1F1E1C */

      color: #1f1e1c;
    }
    &:nth-child(2) {
      background: #b7cb8d;
    }

    &:nth-child(3) {
      background: #f2bc40;
    }
    &:nth-child(4) {
      background: #f8dccd;
    }
    &:last-child {
      background: #ff8659;
    }
  }

  @media (max-width: 1330px) {
    padding: 80px 16px 0;

    &__img {
      max-width: 180px;
    }
  }

  @media (max-width: 1200px) {
    &__top {
      max-width: 498px;
    }
  }

  @media (max-width: 768px) {
    &__list {
      column-gap: 20px;
      row-gap: 20px;
    }

    &__item {
      &-title {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 700px) {
    &__list {
      grid-template-columns: repeat(1, 1fr);
    }

    &__img {
      display: none;
    }
  }

  @media (max-width: 480px) {
    &__subtitle {
      font-size: 10px;
    }

    &__title {
      font-size: 24px;
    }

    &__subtitle,
    &__title {
      text-align: left;
    }

    &__top {
      align-items: flex-start;
    }

    &__top {
      max-width: none;
      width: 100%;
    }
  }
}

.button {
  overflow: hidden;
  background: #ff8324;
  border-radius: 8px;
  padding: 19px 30px;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000200;
  position: relative;
  cursor: pointer;
  justify-content: center;
  &-hover {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-radius: 50%;
    background: #000200;
    -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  &-text {
    z-index: 1;
    position: relative;
    transition: 0.5s ease-in-out;
  }
  &:hover {
    color: #fff;
    background-color: #1f1e1c;
    .button-hover {
      width: 225%;
      height: 562.5px;
    }
  }

  @media (max-width: 480px) {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

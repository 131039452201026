.companies {
  width: 100%;
  &__container {
    max-width: 1310px;
    margin: 0 auto;
    background: #f1f1f1;
    border-radius: 12px;
    padding: 64px;
  }

  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    /* or 44px */

    /* #272C24 */

    color: #272c24;
    max-width: 572px;
  }

  &__text {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */

    /* #1F1E1C */

    color: #1f1e1c;
    max-width: 369px;
  }

  &__top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__list {
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
    row-gap: 30px;
  }

  &__item {
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;

    img {
      object-fit: contain;
    }
  }

  @media (max-width: 1330px) {
    padding: 0 16px;

    &__item {
      height: 100px;
      img {
        max-width: 140px;
      }
    }
  }

  @media (max-width: 900px) {
    &__top {
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      margin-bottom: 32px;
    }

    &__list {
      column-gap: 20px;
      row-gap: 20px;
    }
    &__item {
      height: 100px;
      img {
        max-width: 100px;
      }
    }
  }

  @media (max-width: 768px) {
    &__container {
      padding: 32px;
    }
    &__list {
      column-gap: 30px;
      row-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
    }
    &__item {
      height: 120px;
      img {
        max-width: 140px;
      }
    }
  }

  @media (max-width: 576px) {
    &__list {
      column-gap: 30px;
      row-gap: 30px;
      grid-template-columns: repeat(2, 1fr);
    }
    &__item {
      height: 120px;
      img {
        max-width: 140px;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 0;
    &__title {
      font-size: 24px;
      margin-bottom: 24px;
    }
    &__list {
      column-gap: 30px;
      row-gap: 30px;
      grid-template-columns: repeat(2, 1fr);
    }
    &__item {
      height: 80px;
      img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }

  @media (max-width: 375px) {
    padding: 0;

    &__list {
      column-gap: 20px;
      row-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
      margin: 32px -16px 0;
    }
    &__item {
      height: 80px;
      img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
}

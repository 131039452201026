.footer {
  width: 100%;
  background: #f1f1f1;
  &__container {
    max-width: 1310px;
    margin: 0 auto;
    display: flex;
    padding: 82px 0 0 0;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__img {
    margin-right: 68px;
    display: flex;
  }

  .own-site {
    cursor: pointer;
  }
  &__col {
    display: flex;
    padding: 0 0 92px;
    flex-direction: column;
    &-title {
      font-weight: 700;
      font-size: 22px;
      line-height: 130%;
      /* or 29px */

      /* #1F1E1C */

      color: #1f1e1c;
      margin-bottom: 32px;
    }
    &-link {
      font-weight: 700;
      font-size: 16px;
      line-height: 160%;
      /* identical to box height, or 26px */

      display: flex;
      align-items: center;

      /* #272C24 */

      color: #272c24;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
  &__social {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: #272c24;
    transition: 0.3s ease;
    text-decoration: none;
    &.filter-footer {
 
    }

    span {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1f1e1c;
      transition: 0.3s ease;
      margin-right: 16px;
      path {
        fill: #fff;
      }
    }
    &.footer-underline {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
    &:hover {
      span {
        background: #ff8324;
      }
      text-decoration-line: underline;
      text-underline-offset: 4px;
      color: #ff8324;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__links {
    margin-top: 24px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #1f1e1c;
    margin-bottom: 20px;
  }

  &__text {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #1f1e1c;
    margin-bottom: 32px;
  }

  &__title {
    width: 240px;
  }
  &__text {
    width: 264px;
  }

  &__socials {
    display: flex;
    align-items: center;

    .footer__social {
      span {
        margin-right: 20px;
      }
    }
    .footer__social:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &__bottom {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: space-between;
    p {
      font-family: 'Gotham Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      color: #272c24;
      display: flex;
      align-items: center;
      white-space: nowrap;

      svg {
        margin-left: 6px;
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    &-col {
      margin-right: 58px;
    }
    &-arrow {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 130%;
      color: #1f1e1c;

      span:first-child {
        transition: 0.25s ease;
        width: 50px;
        height: 50px;
        padding: 13px;
        background: #ffffff;
        border: 2px solid #1f1e1c;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        cursor: pointer;
        path {
          transition: 0.25s ease;
        }
        &:hover {
          background: #1f1e1c;
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  @media (max-width: 1330px) {
    &__container {
      padding: 82px 32px 0;
    }
    &__img {
      min-width: 250px;
      max-width: 270px;
      margin-right: 32px;
      img {
        width: 100%;
      }
    }

    &__row {
      flex-grow: 1;
    }
    &__bottom {
      &-col {
        margin-right: 32px;
      }
    }
  }

  @media (max-width: 1024px) {
    &__container {
      flex-direction: column-reverse;
    }

    &__copy {
      font-family: 'Gotham Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      color: #272c24;
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-top: 32px;
    }

    &__row {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
      flex-direction: column-reverse;
      display: flex;
    }

    &__img {
      min-width: auto;
      max-width: 324px;
      margin-right: 0;
      align-self: center;
    }
    &__col {
      padding: 0 0 48px;
    }
  }

  @media (max-width: 768px) {
    &__bottom-arrow {
      span:first-child {
        margin-right: 0;
      }
      span:last-child {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    &__img {
      width: 100%;
    }

    &__container {
      padding: 48px 16px 0;
      margin-top: 80px;
    }

    &__social {
      font-size: 14px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__text {
      margin-bottom: 24px;
    }

    &__col-title {
      margin-bottom: 24px;
    }

    &__col {
      padding: 0 0 32px;
    }

    &__links {
      margin-top: 16px;
    }
  }
}

.faq {
  width: 100%;
  margin-top: 90px;
  &__container {
    max-width: 1310px;
    margin: 0 auto;
    padding: 80px 0;
    background: #f1f1f1;
    border-radius: 12px;
  }

  &__col {
    max-width: 756px;
    margin: 0 auto;
  }

  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    color: #1f1e1c;
    margin-bottom: 48px;
  }

  &__item {
    font-weight: 700;
    font-size: 18px;
    line-height: 142.2%;
    color: #1f1e1c;
    transition: 0.25s ease;
    padding: 0 32px;

    &-top {
      padding: 24px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: 0.25s ease;
      font-weight: 700;
      font-size: 18px;
      line-height: 142.2%;
      color: #1f1e1c;
      span {
        margin-left: 12px;
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        background: #ffffff;
        transition: 0.25s ease;
        path {
          transition: 0.25s ease;
        }
      }
      &:hover {
        color: #ff8324;
        text-decoration: underline;
        text-underline-offset: 3px;
        path {
          fill: #ff8324;
        }
      }
    }
    &-text {
      display: none;
      padding-right: 62px;
      padding-left: 8px;
      a {
        font-family: 'Gotham Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        /* or 22px */

        color: #535353;
        transition: 0.25s ease;
        cursor: pointer;
        &:hover {
          color: #ff8324;
        }
      }
      span {
        font-family: 'Gotham Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        /* or 22px */

        color: #535353;
        transition: 0.25s ease;
        display: block;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
    &.active {
      padding: 16px 32px 40px;
      border-radius: 8px;
      background: #ffffff;

      .faq__item-top {
        span {
          background: #f1f1f1;
          transform: rotate(45deg);
        }
      }
      .faq__item-text {
        display: block;
      }
    }
  }

  @media (max-width: 1330px) {
    padding: 0 16px;
  }

  @media (max-width: 800px) {
    &__container {
      padding: 40px 32px;
    }
  }

  @media (max-width: 576px) {
    &__container {
      padding: 24px 16px;
    }

    &__item {
      padding: 0 8px;
      &.active {
        padding: 8px 8px 24px;
      }
      &-top {
        font-size: 16px;
        padding: 16px 8px 16px;
        span {
          width: 32px;
          min-width: 32px;
          height: 32px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      &-text {
        padding-right: 32px;
      }
    }
  }

  @media (max-width: 480px) {
    margin-top: 64px;
    padding: 0;
    &__title {
      margin-bottom: 32px;
      font-size: 24px;
    }

    &__container {
      padding: 70px 16px 40px;
    }

    &__item {
      padding: 0 30px;
      &.active {
        padding: 16px 30px 40px;
      }
      &-top {
        font-size: 14px;
        padding: 24px 0 24px;
        span {
          width: 32px;
          min-width: 32px;
          height: 32px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      &-text {
        font-size: 14px;
        padding-right: 32px;
      }
    }
  }

  @media (max-width: 360px) {
    &__item-top {
      font-size: 15px;
    }
  }
}

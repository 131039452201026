.ask {
  width: 100%;

  &__container {
    max-width: 950px;
    margin: 0 auto;
    padding: 80px 0;
    display: flex;
    justify-content: space-between;
  }

  &__col {
    max-width: 465px;
    align-self: center;
  }

  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    /* identical to box height, or 44px */

    /* #272C24 */

    color: #272c24;
    margin-bottom: 20px;
  }

  &__text {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */

    /* #1F1E1C */

    color: #1f1e1c;
  }

  &__img {
    width: 460px;
    min-width: 460px;
    max-width: 460px;
  }
  .button {
    width: fit-content;
    margin-top: 32px;
  }

  @media (max-width: 950px) {
    &__container {
      padding: 80px 48px;
    }
  }

  @media (max-width: 768px) {
    &__container {
      flex-direction: column-reverse;
      align-items: center;
    }

    &__img {
      margin-bottom: 32px;
    }

    &__col {
      margin-right: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__title,
    &__text {
      text-align: center;
    }
  }

  @media (max-width: 576px) {
    &__img {
      width: 100%;
      min-width: auto;
    }
  }

  @media (max-width: 480px) {
    &__container {
      padding: 64px 24px 0;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 24px;
    }
    .button {
      width: 100%;
    }
  }
}
